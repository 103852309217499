import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import DashboardHeader from "./components/Dashboard/DashboardHeader";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Product from "./components/Product";
import Categories from "./components/Categories";
import Login from "./components/Login";
import ContactUs from "./components/ContactUs";
import Dashboard from "./components/Dashboard/Dashboard";
import Invoice1Step from "./components/Dashboard/Invoice1Step";
import Invoice2Step from "./components/Dashboard/Invoice2Step";
import Invoice3Step from "./components/Dashboard/Invoice3Step";
import Invoice4Step from "./components/Dashboard/Invoice4Step";
import Invoice5Step from "./components/Dashboard/Invoice5Step";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLoginSuccess = () => {
        setIsLoggedIn(true); // Update the login state
    };

    const handleLogout = () => {
        setIsLoggedIn(false); // Log the user out
    };

    return (
        <Router>
            {/* Conditionally render the appropriate header */}
            {isLoggedIn ? (
                <DashboardHeader onLogout={handleLogout} />
            ) : (
                <Header isLoggedIn={isLoggedIn} />
            )}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products" element={<Categories />} />
                <Route path="/products/:categoryId" element={<Product />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />

                {/* Protected Dashboard Routes */}
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard/Invoice1Step"
                    element={
                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                            <Invoice1Step />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard/Invoice2Step"
                    element={
                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                            <Invoice2Step />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard/Invoice3Step"
                    element={
                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                            <Invoice3Step />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard/Invoice4Step"
                    element={
                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                            <Invoice4Step />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard/Invoice5Step"
                    element={
                        <ProtectedRoute isLoggedIn={isLoggedIn}>
                            <Invoice5Step />
                        </ProtectedRoute>
                    }
                />
                 
            </Routes>
            {isLoggedIn ? (
                <></>
            ) : (
                <Footer isLoggedIn={isLoggedIn} />
            )}
        </Router>
    );
}

export default App;
