import React from "react";
import "./../styles/Home.css";

const Home = () => {
  return (
    <div className="home">
      <div className="hero-section">
        <h1>Welcome to MyApp</h1>
        <p>Your one-stop solution for amazing products!</p>
        <img
          src="https://via.placeholder.com/800x400"
          alt="Hero"
          className="hero-image"
        />
      </div>
    </div>
  );
};

export default Home;
