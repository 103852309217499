import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./../../styles/Dashboard/Invoice2Step.css";
import "./../../styles/Dashboard/Dashboard.css";

const Invoice2Step = () => {
  // State to manage the visibility of the "Invoice" submenu
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

  // Toggle function to show/hide the "Invoice" submenu
  const toggleInvoice = () => {
    setIsInvoiceOpen(!isInvoiceOpen);
  };
  const [invoices, setInvoices] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    invoiceNumber: "",
    billToName: "",
    billToAdd: "",
    billToAddresOne: "",
    billToAddresTwo: "",
    billToPinCode: 0,
    billToState: "",
    billToStateCode: "",
    billToGstNo: "",
    shipToName: "",
    shipToAdd: "",
    shipToAddOne: "",
    shipToAddTwo: "",
    shipToPinCode: 0,
    shipToState: "",
    shipToStateCode: "",
    shipToGstNo: "",
  });

  const apiBaseUrl = "http://localhost:8089/myank/invoice/rc";

  // Fetch all invoices on component load
  useEffect(() => {
    fetchInvoices();
  }, []);
  const handleSameAsBillTo = (e) => {
    if (e.target.checked) {
      setFormData((prevData) => ({
        ...prevData,
        shipToName: prevData.billToName,
        shipToAdd: prevData.billToAdd,
        shipToAddOne: prevData.billToAddresOne,
        shipToAddTwo: prevData.billToAddresTwo,
        shipToPinCode: prevData.billToPinCode,
        shipToState: prevData.billToState,
        shipToStateCode: prevData.billToStateCode,
        shipToGstNo: prevData.billToGstNo,
      }));
    } else {
      // Clear the "Ship To" fields if unchecked
      setFormData((prevData) => ({
        ...prevData,
        shipToName: "",
        shipToAdd: "",
        shipToAddOne: "",
        shipToAddTwo: "",
        shipToPinCode: 0,
        shipToState: "",
        shipToStateCode: "",
        shipToGstNo: "",
      }));
    }
  };

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/all`);
      setInvoices(response.data.object); // Assuming response structure matches provided sample
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData, // Preserve the existing values
      [e.target.name]: e.target.value, // Dynamically update the changed field
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Fetch value from the state
      const headers = {
        invoiceNumber: formData.invoiceNumber || "", // Take value from input field
      };
      console.log("Headers:", headers); // Debugging purpose

      const response = await axios.post(
        `${apiBaseUrl}/createOrUpdate`,
        formData,
        { headers }
      );
      alert(response.data.message);
      setFormData({
        id: "", // update time
        invoiceNumber: "", // Initialize with an empty string
        billToName: "",
        billToAdd: "",
        billToAddresOne: "",
        billToAddresTwo: "",
        billToPinCode: 0,
        billToState: "",
        billToStateCode: "",
        billToGstNo: "",
        shipToName: "",
        shipToAdd: "",
        shipToAddOne: "",
        shipToAddTwo: "",
        shipToPinCode: 0,
        shipToState: "",
        shipToStateCode: "",
        shipToGstNo: "",
      }); // Reset form
      fetchInvoices(); // Refresh invoice list
    } catch (error) {
      console.error("Error saving/updating invoice:", error);
    }
  };

  const handleEdit = (invoice) => {
    setFormData({
      ...invoice,
      invoiceNumber: invoice.invoiceDetailCustomDTO.invoiceNumber,
    });
  };
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this invoice?"
    );
    if (confirmDelete) {
      try {
        // Send DELETE request to your API
        await axios.delete(`${apiBaseUrl}/deleteByIds`, {
          headers: { ids: id },
        });
        fetchInvoices();
        alert("Invoice deleted successfully!");
      } catch (error) {
        console.error("Error deleting invoice:", error);
        alert("Failed to delete the invoice. Please try again.");
      }
    }
  };

  return (
    <div className="dashboard-container">
      {/* Sidebar */}

      <div className="sidebar">
        <ul>
          <li>
            <Link to="/dashboard">{"\u00A0"}Dashboard</Link>
          </li>
          {/* Invoice section with toggle */}
          <li>
            <button className="toggle-button" onClick={toggleInvoice}>
              Invoice
            </button>
            {isInvoiceOpen && ( // Conditionally render submenu
              <ul className="submenu">
                <li>
                  <Link to="/dashboard/Invoice1Step">Basic Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice2Step">Address Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice3Step">Product Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice4Step">Transaction Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice5Step">Final GST and Pdf</Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
      <div className="sidebarleft">
        <h1 className="title-container">
          {" "}
          2nd Step Receiver Consignee Address Details
        </h1>
        <form onSubmit={handleSubmit} className="invoice-form ">
          <h2>{formData.id ? "Update Address" : "Create New Address"}</h2>
          <div className="form-row">
            <div className="form-groups">
              <label htmlFor="billToName">Bill To Name:</label>
              <input
                type="text"
                name="billToName"
                id="billToName"
                value={formData.billToName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-groups">
              <label htmlFor="billToAdd">Bill To Address:</label>
              <input
                type="text"
                name="billToAdd"
                id="billToAdd"
                value={formData.billToAdd}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-groups">
              <label htmlFor="billToAddresOne">Bill To Addres One:</label>
              <input
                type="text"
                name="billToAddresOne"
                id="billToAddresOne"
                value={formData.billToAddresOne}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-groups">
              <label htmlFor="billToAddresTwo">Bill To Addres Two:</label>
              <input
                type="text"
                name="billToAddresTwo"
                id="billToAddresTwo"
                value={formData.billToAddresTwo}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
          <div className="form-groups">
            <label
              htmlFor="invoiceNumber"
              style={{color: "red" }}
            >
              Invoice Number:{" "}
            </label>
            <input
              type="number"
              name="invoiceNumber" 
              id="invoiceNumber"
              value={formData.invoiceNumber} 
              onChange={handleInputChange} 
              required
            />
          </div>
            <div className="form-groups">
              <label htmlFor="billToPinCode">Bill To PinCode:</label>
              <input
                type="number"
                name="billToPinCode"
                id="billToPinCode"
                value={formData.billToPinCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-groups">
              <label htmlFor="billToState">Bill To State:</label>
              <input
                type="text"
                name="billToState"
                id="billToState"
                value={formData.billToState}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-groups">
              <label htmlFor="billToStateCode">Bill To StateCode:</label>
              <input
                type="text"
                name="billToStateCode"
                id="billToStateCode"
                value={formData.billToStateCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-groups">
              <label htmlFor="billToGstNo">Bill To GstNo:</label>
              <input
                type="text"
                name="billToGstNo"
                id="billToGstNo"
                value={formData.billToGstNo}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <input
            type="checkbox"
            id="sameAsBillTo"
            onChange={handleSameAsBillTo}
          />
          <div className="form-row">
            <div className="form-groups">
              <label htmlFor="shipToName">Ship To Name:</label>
              <input
                type="text"
                name="shipToName"
                id="shipToName"
                value={formData.shipToName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-groups">
              <label htmlFor="shipToAdd">Ship To Address:</label>
              <input
                type="text"
                name="shipToAdd"
                id="shipToAdd"
                value={formData.shipToAdd}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-groups">
              <label htmlFor="shipToAddOne">Ship To Addres One:</label>
              <input
                type="text"
                name="shipToAddOne"
                id="shipToAddOne"
                value={formData.shipToAddOne}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-groups">
              <label htmlFor="shipToAddTwo">Ship To Addres Two:</label>
              <input
                type="text"
                name="shipToAddTwo"
                id="shipToAddTwo"
                value={formData.shipToAddTwo}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-groups">
              <label htmlFor="shipToPinCode">Ship To PinCode:</label>
              <input
                type="number"
                name="shipToPinCode"
                id="shipToPinCode"
                value={formData.shipToPinCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-groups">
              <label htmlFor="shipToState">Ship To State:</label>
              <input
                type="text"
                name="shipToState"
                id="shipToState"
                value={formData.shipToState}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-groups">
              <label htmlFor="shipToStateCode">Ship To StateCode:</label>
              <input
                type="text"
                name="shipToStateCode"
                id="shipToStateCode"
                value={formData.shipToStateCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-groups">
              <label htmlFor="shipToGstNo">Ship To GstNo:</label>
              <input
                type="text"
                name="shipToGstNo"
                id="shipToGstNo"
                value={formData.shipToGstNo}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <button type="submit" className="submit-btn">
            Save
          </button>
        </form>

        <h2>All Receiver Consignee Address Details</h2>
        <table border="1" style={{ width: "100%", textAlign: "left" }}>
          <thead>
            <tr>
              <th>Invoice No.</th>
              <th>Bill Name</th>
              <th>Bill Address</th>
              <th>Bill Address 1</th>
              <th>Bill Address 2</th>
              <th>Bill PinCode</th>
              <th>Bill State</th>
              <th>Bill State Code</th>
              <th>Bill Gst no.</th>
              <th>Ship Name</th>
              <th>Ship Address</th>
              <th>Ship Address 1</th>
              <th>Ship Address 2</th>
              <th>Ship PinCode</th>
              <th>Ship State</th>
              <th>Ship State Code</th>
              <th>Ship Gst no.</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody style={{ marginBottom: "200px" }}>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>{invoice.invoiceDetailCustomDTO.invoiceNumber}</td>
                <td> {invoice.billToName}</td>
                <td>{invoice.billToAdd}</td>
                <td>{invoice.billToAddresOne}</td>
                <td>{invoice.billToAddresTwo}</td>
                <td>{invoice.billToPinCode}</td>
                <td>{invoice.billToState}</td>
                <td>{invoice.billToStateCode}</td>
                <td>{invoice.billToGstNo}</td>
                <td>{invoice.shipToName}</td>
                <td>{invoice.shipToAdd}</td>
                <td>{invoice.shipToAddOne}</td>
                <td>{invoice.shipToAddTwo}</td>
                <td>{invoice.shipToPinCode}</td>
                <td>{invoice.shipToState}</td>
                <td>{invoice.shipToStateCode}</td>
                <td>{invoice.shipToGstNo}</td>
                <td>
                  <button
                    onClick={() => handleEdit(invoice)}
                    className="green-btn"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(invoice.id)}
                    className="red-btn"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Invoice2Step;
