import React from "react";
import { Link } from "react-router-dom";
import "./../styles/Categories.css";

const Categories = () => {
  const categories = [
    { id: 1, name: "Accumulators", image: "/images/accumulators.png" },
    { id: 2, name: "Grippers", image: "/images/grippers.png" },
    { id: 3, name: "Linear Actuators", image: "/images/linear-actuators.png" },
    { id: 4, name: "Manual Stages", image: "/images/manual-stages.png" },
    { id: 5, name: "Motorized Stages", image: "/images/motorized-tables.png" },
    { id: 6, name: "Robots and Systems", image: "/images/robots.png" },
    { id: 7, name: "Rotary Actuators", image: "/images/rotary-actuators.png" },
    { id: 8, name: "Vacuum Cups", image: "/images/vacuum-cups.png" },
  ];

  return (
    <div className="categories">
      <h1>Cylinders and Actuators Categories</h1>
      <div className="category-list">
        {categories.map((category) => (
          <div key={category.id} className="category-item">
            <Link to={`/products/${category.id}`}>
              <img src={category.image} alt={category.name} />
              <p>{category.name}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
