import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./../../styles/Dashboard/Dashboard.css";
import "./../../styles/Dashboard/Invoice5Step.css";

const Dashboard = () => {
   // State to manage the visibility of the "Invoice" submenu
   const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

   // Toggle function to show/hide the "Invoice" submenu
   const toggleInvoice = () => {
     setIsInvoiceOpen(!isInvoiceOpen);
   };
   const [invoices, setInvoices] = useState([]);
   const apiBaseUrl = "http://localhost:8089/myank/invoice";
 
   // Fetch all invoices on component load
   useEffect(() => {
     fetchInvoices();
   }, []);
 
   const fetchInvoices = async () => {
     try {
       const response = await axios.get(`${apiBaseUrl}/gst/all`);
       setInvoices(response.data.object); // Assuming response structure matches provided sample
     } catch (error) {
       console.error("Error fetching invoices:", error);
     }
   };
   const handlePdfGenerate = async (id) => {
     try {
       const response = await axios.get(`${apiBaseUrl}/pdfGenerator`, {
         headers: { invoiceNumber: id },
       });
   
       if (response.data && response.data.object) {
         let fileUrl = response.data.object;
         console.log(fileUrl);
         window.open(fileUrl, '_blank');
       } else {
         console.error("No URL found in the response");
       }
     } catch (error) {
       console.error("Error generating PDF:", error);
     }
   };
   
 
   return (
     <div className="dashboard-container">
       {/* Sidebar */}
 
       <div className="sidebar">
         <ul>
           <li>
             <Link to="/dashboard">{'\u00A0'}Dashboard</Link>
           </li>
           {/* Invoice section with toggle */}
           <li>
             <button className="toggle-button" onClick={toggleInvoice}>
               Invoice
             </button>
             {isInvoiceOpen && ( // Conditionally render submenu
               <ul className="submenu">
                 <li>
                   <Link to="/dashboard/Invoice1Step">Basic Details</Link>
                 </li>
                 <li>
                   <Link to="/dashboard/Invoice2Step">Address Details</Link>
                 </li>
                 <li>
                   <Link to="/dashboard/Invoice3Step">Product Details</Link>
                 </li>
                 <li>
                   <Link to="/dashboard/Invoice4Step">Transaction Details</Link>
                 </li>
                 <li>
                   <Link to="/dashboard/Invoice5Step">Final GST and Pdf</Link>
                 </li>
               </ul>
             )}
           </li>
         </ul>
       </div>
       <div className="sidebarleft">
         <h1 className="title-container">
           {" "}
           Details
         </h1> 
         <h2>All Percentage and Amount final Details        </h2>
         <table border="1" style={{ width: "100%", textAlign: "left" }}>
           <thead>
             <tr>
               <th>Invoice No.</th>
               <th>Consult Person</th>
               <th>Total Quantity</th>
               <th>Total Amount</th>
               <th>Discount ₹</th>
               <th>Freight Chrage ₹</th>
               <th>PF Charge ₹</th>
               <th>Sub Total</th>
               <th>CGST ₹</th>
               <th>SGST ₹</th>
               <th>IGST ₹</th>
               <th>Round Off</th>
               <th>Grant Total</th>
               <th>Actions</th>
             </tr>
           </thead>
           <tbody style={{ marginBottom: "200px" }}>
             {invoices.map((invoice) => (
               <tr key={invoice.id}>
                 <td>{invoice.invoiceDetailCustomDTO.invoiceNumber}</td>
                 <td>{invoice.discountPercentage}</td>
                 <td>{invoice.discountPercentage}</td>
                 <td>{invoice.discountPercentage}</td>
                 <td>{parseFloat(invoice.discountAmount).toFixed(2)}</td>
                 <td>{parseFloat(invoice.freightChrageAmount).toFixed(2)}</td>
                 <td>{parseFloat(invoice.pfChrageAmount).toFixed(2)}</td>
                 <td>{parseFloat(invoice.subtotal).toFixed(2)}</td>
                 <td>{parseFloat(invoice.cgstAmount).toFixed(2)}</td>
                 <td>{parseFloat(invoice.sgstAmount).toFixed(2)}</td>
                 <td>{parseFloat(invoice.igstAmount).toFixed(2)}</td>
                 <td>{parseFloat(invoice.roundOff).toFixed(2)}</td>
                 <td>{parseFloat(invoice.grantTotal).toFixed(2)}</td>
                 <td>
                 <button
                     onClick={() => handlePdfGenerate(invoice.invoiceDetailCustomDTO.invoiceNumber)}
                     className="yellow-btn"
                   >
                     PDF 
                   </button>
               
                 </td>
               </tr>
             ))}
           </tbody>
         </table>
       </div>
     </div>
   );
 };
 

export default Dashboard;
