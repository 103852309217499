import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./../../styles/Dashboard/Invoice1Step.css";
import "./../../styles/Dashboard/Dashboard.css";

const Invoice1Step = () => {
  // State to manage the visibility of the "Invoice" submenu
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

  // Toggle function to show/hide the "Invoice" submenu
  const toggleInvoice = () => {
    setIsInvoiceOpen(!isInvoiceOpen);
  };
  const [invoices, setInvoices] = useState([]);
  const [formData, setFormData] = useState({
    id: "", // Leave empty for new invoices; fill this to update
    vendorCode: "",
    consultPerson: "",
    taxisPayableonReverseCharge: "NO",
    poNumber: "",
    poDate: "",
    placeofSupply: "",
    transportationMode: "",
    vehicleNo: "",
  });

  const apiBaseUrl = "http://localhost:8089/myank/invoice";

  // Fetch all invoices on component load
  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/all`);
      setInvoices(response.data.object); // Assuming response structure matches provided sample
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = {
        ...formData,
        supplyDate: formData.supplyDate
          ? new Date(formData.supplyDate).getTime()
          : null,
        poDate: formData.poDate ? new Date(formData.poDate).getTime() : null,
      };

      const response = await axios.post(
        `${apiBaseUrl}/createOrUpdate`,
        updatedFormData
      );
      alert(response.data.message);
      setFormData({
        id: "",
        supplyDate: "",
        vendorCode: "",
        consultPerson: "",
        taxisPayableonReverseCharge: "NO",
        poNumber: "",
        poDate: "",
        placeofSupply: "",
        transportationMode: "",
        vehicleNo: "",
      }); // Reset form
      fetchInvoices(); // Refresh invoice list
    } catch (error) {
      console.error("Error saving/updating invoice:", error);
    }
  };

  const handleEdit = (invoice) => {
    setFormData({
      ...invoice,
      supplyDate: invoice.supplyDate
        ? new Date(invoice.supplyDate).toISOString().split("T")[0]
        : "", // Convert timestamp to date string (YYYY-MM-DD)
      poDate: invoice.poDate
        ? new Date(invoice.poDate).toISOString().split("T")[0]
        : "", // Convert timestamp to date string (YYYY-MM-DD)
    });
  };
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this invoice?");
    if (confirmDelete) {
      try {
        // Send DELETE request to your API
        await axios.delete(`${apiBaseUrl}/deleteByIds`, {
          headers: { ids: id },
        });
        fetchInvoices();
        alert("Invoice deleted successfully!");
      } catch (error) {
        console.error("Error deleting invoice:", error);
        alert("Failed to delete the invoice. Please try again.");
      }
    }
  };
  
  return (
    <div className="dashboard-container">
      {/* Sidebar */}

      <div className="sidebar">
        <ul>
          <li>
            <Link to="/dashboard">{'\u00A0'}Dashboard</Link>
          </li>
          {/* Invoice section with toggle */}
          <li>
            <button className="toggle-button" onClick={toggleInvoice}>
              Invoice
            </button>
            {isInvoiceOpen && ( // Conditionally render submenu
              <ul className="submenu">
                <li>
                  <Link to="/dashboard/Invoice1Step">Basic Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice2Step">Address Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice3Step">Product Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice4Step">Transaction Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice5Step">Final GST and Pdf</Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
      <div className="sidebarleft">
        <h1 className="title-container"> 1st Step Basic Details</h1>
        <form onSubmit={handleSubmit} className="invoice-form ">
          <h2>{formData.id ? "Update Invoice" : "Create New Invoice"}</h2>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="supplyDate">Supply Date:</label>
              <input
                type="date"
                name="supplyDate"
                id="supplyDate"
                value={formData.supplyDate}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="vendorCode">Vendor Code:</label>
              <input
                type="text"
                name="vendorCode"
                id="vendorCode"
                value={formData.vendorCode}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="taxisPayableonReverseCharge">
                Tax Payable on Reverse Charge:
              </label>
              <select
                name="taxisPayableonReverseCharge"
                id="taxisPayableonReverseCharge"
                value={formData.taxisPayableonReverseCharge}
                onChange={handleInputChange}
              >
                <option value="NO">NO</option>
                <option value="YES">YES</option>
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="consultPerson">Consult Person:</label>
              <input
                type="text"
                name="consultPerson"
                id="consultPerson"
                value={formData.consultPerson}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="poNumber">PO Number:</label>
              <input
                type="text"
                name="poNumber"
                id="poNumber"
                value={formData.poNumber}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="poDate">PO Date:</label>
              <input
                type="date"
                name="poDate"
                id="poDate"
                value={formData.poDate}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="placeofSupply">Place of Supply:</label>
              <input
                type="text"
                name="placeofSupply"
                id="placeofSupply"
                value={formData.placeofSupply}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="transportationMode">Transportation Mode:</label>
              <input
                type="text"
                name="transportationMode"
                id="transportationMode"
                value={formData.transportationMode}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="vehicleNo">Vehicle No:</label>
              <input
                type="text"
                name="vehicleNo"
                id="vehicleNo"
                value={formData.vehicleNo}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <button type="submit" className="submit-btn">
            Save
          </button>
        </form>

        <h2>All Invoices</h2>
        <table border="1" style={{ width: "100%", textAlign: "left" }}>
          <thead>
            <tr>
              <th>Invoice No.</th>
              <th>Supply Date</th>
              <th>Vendor Code</th>
              <th>Consult Person</th>
              <th>Tax Payable</th>
              <th>PO Number</th>
              <th>PO Date</th>
              <th>Place of Supply</th>
              <th>Transportation Mode</th>
              <th>Vehicle No.</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody style={{ marginBottom: "200px" }}>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>{invoice.invoiceNumber}</td>
                <td>
                  {new Date(invoice.supplyDate).toLocaleDateString("en-GB")}
                </td>
                <td>{invoice.vendorCode}</td>
                <td>{invoice.consultPerson}</td>
                <td>{invoice.taxisPayableonReverseCharge}</td>
                <td>{invoice.poNumber}</td>
                <td>{new Date(invoice.poDate).toLocaleDateString("en-GB")}</td>
                <td>{invoice.placeofSupply}</td>
                <td>{invoice.transportationMode}</td>
                <td>{invoice.vehicleNo}</td>
                <td>
                  <button
                    onClick={() => handleEdit(invoice)}
                    className="green-btn"
                  >
                    Edit
                  </button>
               
                
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Invoice1Step;
