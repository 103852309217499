import React from "react";
import { useParams } from "react-router-dom";
import "./../styles/Product.css";

const Product = () => {
  const { categoryId } = useParams();

  // Example product data for each category
  const products = {
    1: [
      { id: 101, name: "Accumulator 1", image: "/images/accumulator1.png" },
      { id: 102, name: "Accumulator 2", image: "/images/accumulator2.png" },
    ],
    2: [
      { id: 201, name: "Gripper 1", image: "/images/gripper1.png" },
      { id: 202, name: "Gripper 2", image: "/images/gripper2.png" },
    ],
    // Add more product lists for other categories
  };

  const productList = products[categoryId] || [];

  return (
    <div className="product-page">
      <h1>Products</h1>
      <div className="product-list">
        {productList.map((product) => (
          <div key={product.id} className="product-item">
            <img src={product.image} alt={product.name} />
            <p>{product.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
