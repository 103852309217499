import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./../../styles/Dashboard/Invoice5Step.css";
import "./../../styles/Dashboard/Dashboard.css";

const Invoice5Step = () => {
  // State to manage the visibility of the "Invoice" submenu
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

  // Toggle function to show/hide the "Invoice" submenu
  const toggleInvoice = () => {
    setIsInvoiceOpen(!isInvoiceOpen);
  };
  const [invoices, setInvoices] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    invoiceNumber: "",
    discountPercentage:0.0,
    freightChragePercentage: 0.0,
    pfChragePercentage: 0.0,
    cgstPercentage: 0.0,
    sgstPercentage: 0.0,
    igstPercentage: 0.0,
  });

  const apiBaseUrl = "http://localhost:8089/myank/invoice";

  // Fetch all invoices on component load
  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/gst/all`);
      setInvoices(response.data.object); // Assuming response structure matches provided sample
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        invoiceNumber: formData.invoiceNumber || "", // Take value from input field
      };
      const response = await axios.post(
        `${apiBaseUrl}/gst/createOrUpdate`,
        formData,
        { headers }
      );
      alert(response.data.message);
      setFormData({
        id: "", // update time
        invoiceNumber: "",
        discountPercentage:0.0,
        freightChragePercentage: 0.0,
        pfChragePercentage: 0.0,
        cgstPercentage: 0.0,
        sgstPercentage: 0.0,
        igstPercentage: 0.0,
      }); // Reset form
      fetchInvoices(); // Refresh invoice list
    } catch (error) {
      console.error("Error saving/updating invoice:", error);
    }
  };

  const handleEdit = (invoice) => {
    setFormData({
      ...invoice,
      invoiceNumber: invoice.invoiceDetailCustomDTO.invoiceNumber,
    });
  };
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this invoice?");
    if (confirmDelete) {
      try {
        // Send DELETE request to your API
        await axios.delete(`${apiBaseUrl}/gst/deleteByIds`, {
          headers: { ids: id },
        });
        fetchInvoices();
        alert("Invoice deleted successfully!");
      } catch (error) {
        console.error("Error deleting invoice:", error);
        alert("Failed to delete the invoice. Please try again.");
      }
    }
  };
  const handlePdfGenerate = async (id) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/pdfGenerator`, {
        headers: { invoiceNumber: id },
      });
  
      if (response.data && response.data.object) {
        let fileUrl = response.data.object;
        console.log(fileUrl);
        window.open(fileUrl, '_blank');
      } else {
        console.error("No URL found in the response");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
  

  return (
    <div className="dashboard-container">
      {/* Sidebar */}

      <div className="sidebar">
        <ul>
          <li>
            <Link to="/dashboard">{'\u00A0'}Dashboard</Link>
          </li>
          {/* Invoice section with toggle */}
          <li>
            <button className="toggle-button" onClick={toggleInvoice}>
              Invoice
            </button>
            {isInvoiceOpen && ( // Conditionally render submenu
              <ul className="submenu">
                <li>
                  <Link to="/dashboard/Invoice1Step">Basic Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice2Step">Address Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice3Step">Product Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice4Step">Transaction Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice5Step">Final GST and Pdf</Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
      <div className="sidebarleft">
        <h1 className="title-container">
          {" "}
          5th Step All % and ₹ final Details
        </h1>
        <form onSubmit={handleSubmit} className="invoice-form ">
          <h2>{formData.id ? "Update Address" : "Create New Address"}</h2>

          <div className="form-row">
           <div className="form-group">
              <label htmlFor="discountPercentage">Discount Percentage:</label>
              <input
                type="number"
                name="discountPercentage"
                id="discountPercentage"
                value={formData.discountPercentage}
                step="any" // Allows decimal values
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="freightChragePercentage">Freight Chrage Percentage:</label>
              <input
                type="number"
                name="freightChragePercentage"
                id="freightChragePercentage"
                value={formData.freightChragePercentage}
                step="any" // Allows decimal values
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="pfChragePercentage">PF Chrage Percentage:</label>
              <input
                type="number"
                name="pfChragePercentage"
                id="pfChragePercentage"
                value={formData.pfChragePercentage}
                step="any" // Allows decimal values
                onChange={handleInputChange}
                required
              />
            </div>
          </div> 
           <div className="form-row">
           <div className="form-group">
            <label
              htmlFor="invoiceNumber"
              style={{color: "red" }}
            >
              Invoice Number:{" "}
            </label>
            <input
              type="number"
              name="invoiceNumber" 
              id="invoiceNumber"
              value={formData.invoiceNumber} 
              onChange={handleInputChange} 
              required
            />
          </div>
           <div className="form-group">
              <label htmlFor="cgstPercentage">CGST Percentage:</label>
              <input
                type="number"
                name="cgstPercentage"
                id="cgstPercentage"
                value={formData.cgstPercentage}
                step="any" // Allows decimal values
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="sgstPercentage">SGST Percentage:</label>
              <input
                type="number"
                name="sgstPercentage"
                id="sgstPercentage"
                value={formData.sgstPercentage}
                step="any" // Allows decimal values
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="igstPercentage">IGST Percentage:</label>
              <input
                type="number"
                name="igstPercentage"
                id="igstPercentage"
                value={formData.igstPercentage}
                step="any" // Allows decimal values
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <button type="submit" className="submit-btn">
            Save
          </button>
        </form>

        <h2>All Percentage and Amount final Details        </h2>
        <table border="1" style={{ width: "100%", textAlign: "left" }}>
          <thead>
            <tr>
              <th>Invoice No.</th>
              <th>Discount %</th>
              <th>Discount ₹</th>
              <th>Freight Chrage %</th>
              <th>Freight Chrage ₹</th>
              <th>PF Charge %</th>
              <th>PF Charge ₹</th>
              <th>Sub Total</th>
              <th>CGST %</th>
              <th>CGST ₹</th>
              <th>CGST In Words</th>
              <th>SGST %</th>
              <th>SGST ₹</th>
              <th>SGST In Words</th>
              <th>IGST %</th>
              <th>IGST ₹</th>
              <th>IGST In Words</th>
              <th>Round Off</th>
              <th>Grant Total</th>
              <th>Grant Total In Words</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody style={{ marginBottom: "200px" }}>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>{invoice.invoiceDetailCustomDTO.invoiceNumber}</td>
                <td>{invoice.discountPercentage}</td>
                <td>{parseFloat(invoice.discountAmount).toFixed(2)}</td>
                <td>{invoice.freightChragePercentage}</td>
                <td>{parseFloat(invoice.freightChrageAmount).toFixed(2)}</td>
                <td>{invoice.pfChragePercentage}</td>
                <td>{parseFloat(invoice.pfChrageAmount).toFixed(2)}</td>
                <td>{parseFloat(invoice.subtotal).toFixed(2)}</td>
                <td>{invoice.cgstPercentage}</td>
                <td>{parseFloat(invoice.cgstAmount).toFixed(2)}</td>
                <td>{invoice.cgstAmountWord}</td>
                <td>{invoice.sgstPercentage}</td>
                <td>{parseFloat(invoice.sgstAmount).toFixed(2)}</td>
                <td>{invoice.sgstAmountWord}</td>
                <td>{invoice.igstPercentage}</td>
                <td>{parseFloat(invoice.igstAmount).toFixed(2)}</td>
                <td>{invoice.igstAmountWord}</td>
                <td>{parseFloat(invoice.roundOff).toFixed(2)}</td>
                <td>{parseFloat(invoice.grantTotal).toFixed(2)}</td>
                <td>{invoice.grantTotalWord}</td>
                <td>
                <button
                    onClick={() => handlePdfGenerate(invoice.invoiceDetailCustomDTO.invoiceNumber)}
                    className="yellow-btn"
                  >
                    PDF 
                  </button>
                  <button
                    onClick={() => handleEdit(invoice)}
                    className="green-btn"
                  >
                    Edit
                  </button>
                 
                  <button
                    onClick={() => handleDelete(invoice.id)}
                    className="red-btn"
                  >
                    Delete
                  </button>
              
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Invoice5Step;
