import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./../../styles/Dashboard/Invoice3Step.css";
import "./../../styles/Dashboard/Dashboard.css";

const Invoice3Step = () => {
  // State to manage the visibility of the "Invoice" submenu
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

  // Toggle function to show/hide the "Invoice" submenu
  const toggleInvoice = () => {
    setIsInvoiceOpen(!isInvoiceOpen);
  };
  const [invoices, setInvoices] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    invoiceNumber: "",
    descriptionOfProduct: "",
    hsnCode: "",
    quantity: 0,
    rate: 0.0,
  });

  const apiBaseUrl = "http://localhost:8089/myank/invoice/product";

  // Fetch all invoices on component load
  useEffect(() => {
    fetchInvoices();
  }, []);
  const fetchInvoices = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/all`);
      setInvoices(response.data.object); // Assuming response structure matches provided sample
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        invoiceNumber: formData.invoiceNumber || "", // Take value from input field
      };
      const response = await axios.post(
        `${apiBaseUrl}/createOrUpdate`,
        formData,
        { headers }
      );
      alert(response.data.message);
      setFormData({
        id: "",
        invoiceNumber:"",
        descriptionOfProduct: "",
        hsnCode: "",
        quantity: 0,
        rate: 0.0,
      }); // Reset form
      fetchInvoices(); // Refresh invoice list
    } catch (error) {
      console.error("Error saving/updating invoice:", error);
    }
  };

  const handleEdit = (invoice) => {
    setFormData({
      ...invoice,
      invoiceNumber: invoice.invoiceDetailCustomDTO.invoiceNumber,
    });
  };
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this invoice?");
    if (confirmDelete) {
      try {
        // Send DELETE request to your API
        await axios.delete(`${apiBaseUrl}/deleteByIds`, {
          headers: { ids: id },
        });
        fetchInvoices();
        alert("Invoice deleted successfully!");
      } catch (error) {
        console.error("Error deleting invoice:", error);
        alert("Failed to delete the invoice. Please try again.");
      }
    }
  };

  return (
    <div className="dashboard-container">
      {/* Sidebar */}

      <div className="sidebar">
        <ul>
          <li>
            <Link to="/dashboard">{'\u00A0'}Dashboard</Link>
          </li>
          {/* Invoice section with toggle */}
          <li>
            <button className="toggle-button" onClick={toggleInvoice}>
              Invoice
            </button>
            {isInvoiceOpen && ( // Conditionally render submenu
              <ul className="submenu">
                <li>
                  <Link to="/dashboard/Invoice1Step">Basic Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice2Step">Address Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice3Step">Product Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice4Step">Transaction Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice5Step">Final GST and Pdf</Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
      <div className="sidebarleft">
        <h1 className="title-container">
          {" "}
          3rd Step Description Of Product Details
        </h1>
        <form onSubmit={handleSubmit} className="invoice-form ">
          <h2>{formData.id ? "Update Address" : "Create New Address"}</h2>
         
          <div className="form-row">
        
            <div className="form-group" >
              <label htmlFor="descriptionOfProduct">Description Of Product:</label>
              <input
                type="text"
                name="descriptionOfProduct"
                id="descriptionOfProduct"
                value={formData.descriptionOfProduct}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
          <div className="form-group">
            <label
              htmlFor="invoiceNumber"
              style={{color: "red" }}
            >
              Invoice Number:{" "}
            </label>
            <input
              type="number"
              name="invoiceNumber" 
              id="invoiceNumber"
              value={formData.invoiceNumber} 
              onChange={handleInputChange} 
              required
            />
          </div>
          <div className="form-group">
              <label htmlFor="hsnCode">HSN Code:</label>
              <input
                type="text"
                name="hsnCode"
                id="hsnCode"
                value={formData.hsnCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="quantity">Quantity:</label>
              <input
                type="number"
                name="quantity"
                id="quantity"
                value={formData.quantity}
                onChange={handleInputChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="rate">Rate:</label>
              <input
                type="number"
                name="rate"
                id="rate"
                value={formData.rate}
                step="any" // Allows decimal values
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <button type="submit" className="submit-btn">
            Save
          </button>
        </form>

        <h2>All Description Of Product Details</h2>
        <table border="1" style={{ width: "100%", textAlign: "left" }}>
          <thead>
            <tr>
              <th>Invoice No.</th>
              <th>Description Of Product</th>
              <th>HSN Code</th>
              <th>Quantity</th>
              <th>Rate</th>
              <th>Taxable Value</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody style={{ marginBottom: "200px" }}>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>{invoice.invoiceDetailCustomDTO.invoiceNumber}</td>
                <td>{invoice.descriptionOfProduct}</td>
                <td>{invoice.hsnCode}</td>
                <td>{invoice.quantity}</td>
                <td>{parseFloat(invoice.rate).toFixed(2)}</td>
                <td>{parseFloat(invoice.taxableValue).toFixed(2)}</td>
                <td>
                  <button
                    onClick={() => handleEdit(invoice)}
                    className="green-btn"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(invoice.id)}
                    className="red-btn"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Invoice3Step;
