import React from "react";
import "./../styles/ContactUs.css";
const ContactUs = () => {
  return (
    <div className="contact-container">
      {/* Header Section */}
      <div className="contact-header">
        <h1>CONTACT PARKER</h1>
        <h2>HOW CAN WE HELP YOU?</h2>
        <p>
          Need help with something? Here are some of our most frequently asked
          questions.
        </p>
      </div>
      {/* Card Section */}
      <div className="card-section">
        <div className="card">
          <h3>👥 Contact Sales</h3>
          <p>
            Whether you need assistance selecting the proper product or need to
            talk about a complete system solution, our sales team is ready to
            help.
          </p>
        </div>
        <div className="card">
          <h3>🎧 Contact Support</h3>
          <p>
            Need help? Our dedicated support team is here to assist you! We can
            help with troubleshooting and answer questions about your Parker
            products. We're just a message away.
          </p>
        </div>
        <div className="card">
        <h4>Americas</h4>
          <p>USA, Canada</p>
          <p>Ph: 1-800-272-7537</p>
          <p>Email: cparker@support.parker.com</p>
        </div>
      </div>

     
    </div>
  );
};

export default ContactUs;
