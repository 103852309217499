import React from "react";
import "./../../styles/Dashboard/DashboardHeader.css";

const DashboardHeader = ({ isLoggedIn, onLogout }) => {
  return (
    <header className="header">
      <div className="logo">Dashboard {/* Logo with a link */}</div>
      <nav>
        <ul className="nav-links">
          {isLoggedIn && (
            <li>
              <button className="logout-button" onClick={onLogout}>
                Logout
              </button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default DashboardHeader;
