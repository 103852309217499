import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./../../styles/Dashboard/Invoice4Step.css";
import "./../../styles/Dashboard/Dashboard.css";

const Invoice4Step = () => {
  // State to manage the visibility of the "Invoice" submenu
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

  // Toggle function to show/hide the "Invoice" submenu
  const toggleInvoice = () => {
    setIsInvoiceOpen(!isInvoiceOpen);
  };
  const [invoices, setInvoices] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    invoiceNumber: "",
    totalAmount: 0.0,
    totalQuantity: 0.0,
    bankName: "",
    bankAdd: "",
    bankIfscCode: "",
    bankAccountNumber: "",
  });

  const apiBaseUrl = "http://localhost:8089/myank/invoice/trans";

  // Fetch all invoices on component load
  useEffect(() => {
    fetchInvoices();
  }, []);
  const fetchInvoices = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/all`);
      setInvoices(response.data.object); // Assuming response structure matches provided sample
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        invoiceNumber: formData.invoiceNumber || "", // Take value from input field
      };
      const response = await axios.post(
        `${apiBaseUrl}/createOrUpdate`,
        formData,
        { headers }
      );
      alert(response.data.message);
      setFormData({
        id: "",
        invoiceNumber: "",
    totalAmount: 0.0,
    totalQuantity: 0.0,
    bankName: "",
    bankAdd: "",
    bankIfscCode: "",
    bankAccountNumber: "",
      }); // Reset form
      fetchInvoices(); // Refresh invoice list
    } catch (error) {
      console.error("Error saving/updating invoice:", error);
    }
  };

  const handleEdit = (invoice) => {
    setFormData({
      ...invoice,
      invoiceNumber: invoice.invoiceDetailCustomDTO.invoiceNumber,
    });
  };
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this invoice?"
    );
    if (confirmDelete) {
      try {
        // Send DELETE request to your API
        await axios.delete(`${apiBaseUrl}/deleteByIds`, {
          headers: { ids: id },
        });
        fetchInvoices();
        alert("Invoice deleted successfully!");
      } catch (error) {
        console.error("Error deleting invoice:", error);
        alert("Failed to delete the invoice. Please try again.");
      }
    }
  };

  return (
    <div className="dashboard-container">
      {/* Sidebar */}

      <div className="sidebar">
        <ul>
          <li>
            <Link to="/dashboard">{'\u00A0'}Dashboard</Link>
          </li>
          {/* Invoice section with toggle */}
          <li>
            <button className="toggle-button" onClick={toggleInvoice}>
              Invoice
            </button>
            {isInvoiceOpen && ( // Conditionally render submenu
              <ul className="submenu">
                <li>
                  <Link to="/dashboard/Invoice1Step">Basic Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice2Step">Address Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice3Step">Product Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice4Step">Transaction Details</Link>
                </li>
                <li>
                  <Link to="/dashboard/Invoice5Step">Final GST and Pdf</Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
      <div className="sidebarleft">
        <h1 className="title-container">
          {" "}
          4th Step Bank And all Amounts Details
        </h1>
        <form onSubmit={handleSubmit} className="invoice-form ">
          <h2>{formData.id ? "Update Address" : "Create New Address"}</h2>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="bankName">
              Bank Name:
              </label>
              <input
                type="text"
                name="bankName"
                id="bankName"
                value={formData.bankName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="bankAdd">
              Bank Address:
              </label>
              <input
                type="text"
                name="bankAdd"
                id="bankAdd"
                value={formData.bankAdd}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="bankIfscCode">
              Bank IFSC Code:
              </label>
              <input
                type="text"
                name="bankIfscCode"
                id="bankIfscCode"
                value={formData.bankIfscCode}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
          <div className="form-group">
            <label
              htmlFor="invoiceNumber"
              style={{color: "red" }}
            >
              Invoice Number:{" "}
            </label>
            <input
              type="number"
              name="invoiceNumber" 
              id="invoiceNumber"
              value={formData.invoiceNumber} 
              onChange={handleInputChange} 
              required
            />
          </div>
            <div className="form-group">
              <label htmlFor="bankAccountNumber">Bank Account Number:</label>
              <input
                type="text"
                name="bankAccountNumber"
                id="bankAccountNumber"
                value={formData.bankAccountNumber}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="totalQuantity">Total Quantity:</label>
              <input
                type="number"
                name="totalQuantity"
                id="totalQuantity"
                value={formData.totalQuantity}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="totalAmount">Total Amount:</label>
              <input
                type="number"
                name="totalAmount"
                id="totalAmount"
                value={formData.totalAmount}
                step="any" // Allows decimal values
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <button type="submit" className="submit-btn">
            Save
          </button>
        </form>

        <h2>All Bank Quantity & Amounts Details</h2>
        <table border="1" style={{ width: "100%", textAlign: "left" }}>
          <thead>
            <tr>
              <th>Invoice No.</th>
              <th>Total Quantity</th>
              <th>Total Amount</th>
              <th>Bank Name</th>
              <th>Bank Address</th>
              <th>Bank IFSC Code</th>
              <th>Bank Account Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody style={{ marginBottom: "200px" }}>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>{invoice.invoiceDetailCustomDTO.invoiceNumber}</td>
                <td>{invoice.totalQuantity}</td>
                <td>{parseFloat(invoice.totalAmount).toFixed(2)}</td>
                <td>{invoice.bankName}</td>
                <td>{invoice.bankAdd}</td>
                <td>{invoice.bankIfscCode}</td>
                <td>{invoice.bankAccountNumber}</td>
                <td>
                  <button
                    onClick={() => handleEdit(invoice)}
                    className="green-btn"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(invoice.id)}
                    className="red-btn"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Invoice4Step;
